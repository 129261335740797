const baseApiUrl = 'https://diario-new-test.sintrasviluppo.it/';
const apiUrl = `${baseApiUrl}oapi/`;

export const environment = {
  production: false,
  name: 'staging',
  appName: 'Aboca - Diario alimentare',
  storageVersion: '1.0.0',
  apiUrl,
  authInterceptorUrls: [apiUrl],
  httpRetryConfig: {
    enabled: true,
    verbs: {
      get: { enabled: true, timeout: 30000, maxRetries: 2, retryForStatusesCodes: [500] },
      post: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
      patch: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
      put: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
      delete: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
    },
  },
  loggerConfig: {
    enabled: true,
    maxLogRentention: 20,
    logHttpErrorsWithStatusesCodes: ['0', '4XX', '5XX'],
    logDecoratedExceptions: true,
    logExceptions: true,
    saveLogMessagesInterval: 30000,
    logUrl: 'https://logs.logdna.com/logs/ingest?apikey=bf8cf553dba4a1e882fa09491aaed2c7',
    sendLogs: true,
    sendRequestBody: true,
    sendQueryParams: true,
  },
  tagManager: {
    enabled: false,
    apiKey: 'GTM-K2K385J',
  },
  registerServiceWorker: false,
  sockets: { url: '' },
  mockApiUrl: '',
};
